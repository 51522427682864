import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import cookie from "js-cookie";

const MainSlider = (props) => {
  const [imageSources, setImageSources] = useState([]);
  const [sliderItems, setSliderItems] = useState([]);
  let NCRcities = [
    "Delhi",
    "Gurugram",
    "Faridabad",
    "Noida",
    "Ghaziabad",
    "Greater Noida",
    "Meerut",
    "Sonipat",
    "Panipat",
    "Rohtak",
    "Karnal",
    "Bhiwani",
    "Rewari",
    "Palwal",
    "Jind",
    "Alwar",
    "Bharatpur",
    "Bulandshahr",
    "Bhiwadi",
    "Muzaffarnagar",
    "Shamli",
    "Baghpat",
    "Hapur",
    "New Delhi",
    "Dādri",
  ];
  let addDelhiBanner = async (arr) => {
    if (cookie.get("delhiNCR")) {
      let sources = [];
      // COMMENTING DELHI BANNER LOGIC on April 4 2025 as asked by Jitender. DO NOT REMOVE IT
      // sources.push({
      //   src: "/delhi_banner.avif",
      //   href: "/tiles/delhi?utm_source=google&utm_medium=organic&utm_campaign=Website_banner_delhi&utm_term=Tiles_in_Delhi",
      // });
      sources = sources.concat(arr);
      setImageSources(sources);
    } else {
      let ipInfo = await axios.get(`https://obl-js.orientbell.com/api/ipinfo`);
      if (ipInfo.status == 200) {
        let sources = [];
        if (NCRcities.includes(ipInfo.data.response?.city)) {
          // sources.push({
          //   src: "/delhi_banner.avif",
          //   href: "/tiles/delhi?utm_source=google&utm_medium=organic&utm_campaign=Website_banner_delhi&utm_term=Tiles_in_Delhi",
          // });
          cookie.set("delhiNCR", true);
        }
        sources = sources.concat(arr);
        setImageSources(sources);
      } else {
        setImageSources(arr);
      }
    }
  };
  useEffect(() => {
    const html = props.data;
    const tempElement1 = document.createElement("div");
    tempElement1.innerHTML = html;

    const aElements = tempElement1.getElementsByTagName("a");
    let sources = [];
    // if (NCRcities.includes(props.city?.city)) {
    //   sources.push({
    //     src: "/delhi_banner.jpeg",
    //     href: "/tiles/delhi",
    //   });
    // }
    sources = sources.concat(
      Array.from(aElements)
        .map((a) => {
          const img = a.querySelector("img");
          return {
            src: img ? img.getAttribute("src") : null,
            href: a.getAttribute("href"),
          };
        })
        .filter((source) => source.src !== null)
    ); // Ensure we only include valid images
    // setImageSources(sources);
    addDelhiBanner(sources);
  }, []);

  useEffect(() => {
    const itemsPerRow = 1; // Number of items per row
    const rows = Math.ceil(imageSources.length / itemsPerRow);

    const newSliderItems = [];
    let startIndex = 0;

    for (let i = 0; i < rows; i++) {
      const endIndex = startIndex + itemsPerRow;
      const rowImages = imageSources.slice(startIndex, endIndex);

      const rowItems = rowImages.map((cur, index) => (
        <div className="styletiles-bx-item" key={index}>
          <div className="styletiles-bx">
            <a href={cur.href} target="_blank" rel="noopener noreferrer">
              <img src={cur.src} alt="banner image" />
            </a>
          </div>{" "}
        </div>
      ));

      newSliderItems.push(<div key={i}>{rowItems}</div>);

      startIndex += itemsPerRow;
    }

    setSliderItems(newSliderItems);
  }, [imageSources]);

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    autoplaySpeed: 5000,
    arrows: true,
    dots: false,
    centerPadding: "0px",
  };

  return (
    <section className="home_style style-tiles-section">
      {/* <link
       rel="stylesheet"
       type="text/css"
       charSet="UTF-8"
       href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css"
     />
     <link
       rel="stylesheet"
       type="text/css"
       href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css"
     /> */}
      <div className="container">
        <Slider {...settings} className="category-menu">
          {sliderItems}
        </Slider>
      </div>
    </section>
  );
};

export default MainSlider;
